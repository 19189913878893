// Claimdetails.js

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';

import {ClaimHeader} from './Claimutils'
import ShowClaimDetails from './Showclaimdetails'
import ShowRelated from './Showrelated'
import * as api from './Restapi'
import UserContext from '../context/userContext';
import * as colors from './Colours'


class ClaimDetails extends React.Component {

    constructor(props) {
        super(props)
        let cd = this.props.location.state.data
        let detail = cd.data.detail
        let matx = [{'tx_type': 'service', 'data': cd, 'guid': cd.data.entity.guid}]  // guid of the claim
        matx.push(...detail)
        this.state = { cd: cd,              // claim detail
                        matx: matx,         // array of claim in time seq 
                        related: cd.data.related,
                        observation: null
                        }
    }

    componentDidMount() {
        let user = this.context;
        
        let claimat = this.state.matx
        
        for(let i=0; i<claimat.length; i++) {
            if(claimat[i].tx_type === 'claim_submission')  {
                let sdata = {}
                //sdata['customer_guid'] = "b1e0f3ac-733b-4f8f-b792-7407add203dc"
                sdata['object_guid'] = claimat[i].guid
                api.postCall(sdata, user.idtoken, "submissiondetail", this.updateSubmissionDetailsResponse )
            } else if(claimat[i].tx_type === 'claim_response')  {
                let sdata = {}
                //sdata['customer_guid'] = "b1e0f3ac-733b-4f8f-b792-7407add203dc"
                sdata['object_guid'] = claimat[i].guid
                api.postCall(sdata, user.idtoken, "responsedetail", this.updateResponseDetailsResponse )
            }
        }
        let sdata = {}
        sdata['object_guid'] = this.state.cd.data.entity.guid
        sdata['object_type'] = 'claim'
        api.postCall(sdata, user.idtoken, "claimobservation", this.updateClaimObservation)
    }

    render() {
        const {classes} = this.props
        console.log("this is claimdetails", this.props.location.state.data)
        let cd = this.state.cd
        let header = cd.data.header
        let claimat = this.state.matx
        let related = this.state.related
        console.log('-->', claimat)
        
        return(
            <div className={classes.all} >
                            
                <ClaimHeader header={header} />
                <Divider light variant='fullWidth' /> 
                <ShowClaimDetails data={claimat} />
                <ShowRelated data={related} />
                
            </div>
        )
    }

    findTLelement = (gid) => {
        let m = this.state.matx
        for(let i=0;i<m.length;i++) {
            if(m[i].guid === gid) {
                return i
            }
        }
        return -1
    }

    updateClaimObservation = (sdata) => {
        console.log("got claimObservations", sdata)
        if(sdata.status !== 'success') {
            return 
        }
        let omatx = this.state.matx
        let d = sdata.data
        for(let i=0; i<d.length; i++) {
            if(d[i].category !== 'Audit') {
                let tli = this.findTLelement(d[i].entity.guid)
                if(tli !== -1) {
                    if(Boolean(omatx[tli]['obs'])) {
                        let cnt = omatx[tli]['obs'].count; cnt += 1
                        //let dstr = d[i].category + ':' + d[i].title
                        let obsitem = d[i].data
                        let cty = d[i].category
                        if(Boolean(omatx[tli]['obs']['msg'][cty])) {
                            let amsg = omatx[tli]['obs']['msg']
                            let dstr = ""
                            for(const [kv, vv] of Object.entries(obsitem)) {
                                    if(typeof(vv) === 'string')
                                        dstr = dstr + vv + ", "
                                    }
                            let darr = amsg[cty]; dstr = dstr +  d[i].title
                            darr.push(dstr)
                            amsg[cty] = darr
                            omatx[tli]['obs'] = {'show': true, 'count': cnt, 'msg': amsg}
                        } else {
                            let amsg = omatx[tli]['obs']['msg']
                            let darr = []; let dstr = ""
                            for(const [kv, vv] of Object.entries(obsitem)) {
                                if(typeof(vv) === 'string')
                                    dstr = dstr + vv + ", "
                                }
                            dstr = dstr + d[i].title
                            darr.push(dstr)
                            amsg[cty] = darr
                            omatx[tli]['obs'] = {'show': true, 'count': cnt, 'msg': amsg}
                        }
                        //let darr = omatx[tli]['obs']['msg'][cty]
                        // let darr = omatx[tli]['obs']['msg']
                        //darr.push(dstr)
                        //omatx[tli]['obs'] = {'show': true, 'count': cnt, 'msg': darr}
                    } else {
                        let cty = d[i].category
                        let obsitem = d[i].data; let dstr = ""
                        for(const [kv, vv] of Object.entries(obsitem)) {
                            if(typeof(vv) === 'string')
                                dstr = dstr + vv + ", "
                            }
                        dstr = dstr  + d[i].title
                        let ctyentry = { [cty]: [dstr]}
                        //darr.push(dstr)
                        // let dstr = d[i].category + ':' + d[i].title
                        // let darr = []; darr.push(dstr)
                        omatx[tli]['obs'] = {'show': true, 'count': 1,'msg': ctyentry}
                    }   
                    //console.log("---- Observation for: ", omatx[tli]['obs'])
                }  
            }
        }
        this.setState({observation: d, matx: omatx})
    }

    updateSubmissionDetailsResponse = (sdata) => {
        console.log("got submission details", sdata)
        if(sdata.status !== 'success') {
            return 
        }
        let ma = this.state.matx
        for(let i=0; i<ma.length;i++) {
            if(ma[i].guid === sdata.data.entity.guid &&      // this submission detail is for this entry
                sdata.data.entity.type === "claim_submission") {
                    ma[i]['lineitem'] = []
                    ma[i]['lineitem'].push(...sdata.data.detail.line_items)
                    ma[i]['related'] = []
                    ma[i]['related'].push(...sdata.data.related)
                    console.log( 'got match for submission data:', i)
                    this.setState({matx: ma})
            }
        }
    }

    updateResponseDetailsResponse= (sdata) => {
        console.log("got response details", sdata)
        if(sdata.status !== 'success') {
            return 
        }
        let ma = this.state.matx
        for(let i=0; i<ma.length;i++) {
            if(ma[i].guid === sdata.data.entity.guid &&      // this submission detail is for this entry
                sdata.data.entity.type === "claim_response") {
                    ma[i]['lineitem'] = []
                    ma[i]['lineitem'].push(...sdata.data.detail.line_items)
                    ma[i]['related'] = []
                    ma[i]['related'].push(...sdata.data.related)
                    ma[i]['crossover'] = sdata.data.detail.cross_over
                    ma[i]['diff'] = sdata.data.detail.diff_from_previous
                    console.log( 'got match for response data:', i)
                    this.setState({matx: ma})
            }
        }
    }

}

ClaimDetails.contextType = UserContext; 

const astyle = {
    all: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 25
        //width: '100%'
        
    },
    tlpaper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.bluegrey600,
        flex: 1,
        width: '75%'
        // flexDirection: 'column',
        // alignItems: 'flex-start'
    }
    
}

export default withStyles(astyle)(ClaimDetails)


