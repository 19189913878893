// Artisslogo.js

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import * as colours from './Colours'




const ArtissTextLogo = (props) => {
    const classes = astyle();

    if(props.base === 'white') {
        return(
            <div className={classes.artisslogowhite}> ARTISS </div>
        )
    } else {
    return(
        <div className={classes.artisslogo}> ARTISS </div>
    )}
}


const astyle = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    artisslogo: {
        fontSize: 'xx-large',
        fontWeight: 'bold',
        letterSpacing: 3,
        color: colours.purple600,
        justifyContent: 'flex-start'
    },
    artisslogowhite: {
        fontSize: 'xx-large',
        fontWeight: 'bold',
        letterSpacing: 3,
        color: 'white',
        justifyContent: 'flex-start'
    }

}))

export { ArtissTextLogo }
