// restContext.js

import React from 'react';

const searchDetails = {
    search_response: ""
}

const claimDetails = {
    claim_detail: ""
}
 
const SearchContext = React.createContext(searchDetails);
const ClaimDetailContext = React.createContext(claimDetails);

export { SearchContext, ClaimDetailContext }