// Showrelated.js


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Fab from '@material-ui/core/Fab';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem'
import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';


import * as api from './Restapi'
import UserContext from '../context/userContext';
import {StyledTreeItem} from './Claimutils'
import * as colors from './Colours'


class ShowRelated extends React.Component {

    constructor(props) {
        super(props)
        this.state = {drawopen: false}
    }

    fabClicked = () => {
        this.setState({drawopen: !this.state.drawopen})
    }

    toggleDrawer = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
          this.setState({drawopen: !this.state.drawopen})
    }

    render() {
        const {classes} = this.props
        const rdata = this.props.data

        return(
            <div>
            <Fab
                //variant="extended"
                size="medium"
                aria-label="add"
                className={classes.place}
                onClick={this.fabClicked}
                >
          <AccountTreeIcon className={classes.extendedIcon} />
          </Fab>

        <SwipeableDrawer
        anchor={'bottom'}
        open={this.state.drawopen}
        onClose={this.toggleDrawer}
        onOpen={this.toggleDrawer}
        >
        
            <div className={classes.rpaper} 
                onClick={this.toggleDrawer}
                onKeyDown={this.toggleDrawer} 
            >
            <Grid container spacing={3}>
            {rdata.map((d,i) => {
                return(
                    <Grid item xs={3} key={i}>
                        <Paper>
                        {d.related_entity}
                        {d.relation_title}
                        </Paper>
                    </Grid>
                )})
            }
            </Grid>
            </div>
            
        
        </SwipeableDrawer>
        </div>
        )
    }

}

const astyle = {
    all: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 25
        //width: '100%'
        
    },
    place: {
        position: 'absolute',
        bottom: '2%',
        right: '1%',
        backgroundColor: colors.artissprimary
    },
    rpaper: {
        margin: 20,
        paddingTop: 10,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.bluegrey700,
        alignSelf: 'center',
        //flex: 1,
        width: '50%',
        // flexDirection: 'column',
        // alignItems: 'flex-start'
    },
    root: {
        //height: 264,
        flexGrow: 1,
        maxWidth: 400,
      },
    
}

export default withStyles(astyle)(ShowRelated)