// Admin.js


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';


import * as api from './Restapi'
import UserContext from '../context/userContext';
import * as colors from './Colours'
import DateRangePicker from './Daterangepicker'


class Admin extends React.Component {

    constructor(props) {
        super(props)
        let td = new Date()
        let today = new Date().toLocaleDateString('en-ZA')
        let yes = td.setDate(td.getDate() -1)
        let yesterday = new Date(yes).toLocaleDateString('en-ZA')
        this.state = { opsdata: null, drawopen: false, apiongoing: false,
            sdate: yesterday, edate: today
            }
    }

    componentDidMount() {
        let user = this.context;
        let sdata = {}

        console.log("adim - did mount")
        // [
        //     "charge_summary","li_charge_summary","submission_summary","li_submission_summary","charge_observations","li_charge_amount_by_payor","li_charge_amount_by_physician","li_charge_amount_by_cpt","charge_amount_by_age","li_charge_count_by_payor_and_plan_type","li_charge_count_by_physician","li_charge_count_by_cpt","li_charge_amount_and_count_by_day","li_charge_amount_and_count_by_week"
        // ]

        sdata["table_slugs"] = [ "messages_received_live", "messages_received_live_by_status", "messages_summary_by_month", 
        "messages_summary_by_source_week", "messages_received_by_customer_day", "open_messages_summary", 
        "ingestion_failed_messages", "prep_failed_messages", "etl_failed_messages", "open_messages_by_age", 
        "open_messages_by_status_age", "batch_process_summary", "status_recorded_live", "status_summary", 
        "status_summary_by_customer", "status_exception_summary", "status_error_summary", 
        "status_warn_summary", "status_review_summary", "audit_run_timing_summary", "db_obj_size" ]

        sdata["filter_criteria"] = {
                //"transaction_date": {"literal":"zz","start":"2020/06/01","end":"2020/08/31"}
                "transaction_date": {"literal":"zz","start":this.state.sdate,"end":this.state.edate}
            }
        
        this.setState({apiongoing: true})
        api.postCall(sdata, user.idtoken, "adminOpsStatus", this.adminOpsStatusCallback)
    }

    adminOpsStatusCallback = (sdata) => {
        console.log("got adminOpsStatus")
        this.setState({opsdata: sdata, apiongoing: false})
    }

    tableRowsHeader = (td) => {
        //console.log('tableRowHeader len', td.length)
        let hdr = []
        if(td.length > 0) {
            for (const [key, value] of Object.entries(td[0])) {
                hdr.push(key)
            }
            return hdr
        } else 
            return hdr
    }

    tableRowdata = (td) => {
        //console.log('rowdata ', td)
        let rd = []
        if(Boolean(td)) {
            for (const [key, value] of Object.entries(td)) {
                rd.push(value)
            }
            return rd
        } else
            return rd
    }

    fabClicked = () => {
        this.setState({drawopen: !this.state.drawopen})
    }

    datepickerCallback = (pdrawstatus, psdate, pedate) => {
        let user = this.context;
        let sdata = {}
        this.setState({drawopen: pdrawstatus, sdate: psdate, edate: pedate, apiongoing: true})

        sdata["table_slugs"] = [ "messages_received_live", "messages_received_live_by_status", "messages_summary_by_month", 
        "messages_summary_by_source_week", "messages_received_by_customer_day", "open_messages_summary", 
        "ingestion_failed_messages", "prep_failed_messages", "etl_failed_messages", "open_messages_by_age", 
        "open_messages_by_status_age", "batch_process_summary", "status_recorded_live", "status_summary", 
        "status_summary_by_customer", "status_exception_summary", "status_error_summary", 
        "status_warn_summary", "status_review_summary", "audit_run_timing_summary", "db_obj_size" ]

        sdata["filter_criteria"] = {
                //"transaction_date": {"literal":"zz","start":"2020/06/01","end":"2020/08/31"}
                "transaction_date": {"start":psdate,"end":pedate}
            }
        console.log('datepicker callback', psdate, pedate)
        api.postCall(sdata, user.idtoken, "adminOpsStatus", this.adminOpsStatusCallback)
    }

    render() {
        const {classes} = this.props
        
        if(Boolean(this.state.opsdata)) {
            let odata = this.state.opsdata.data                        
            let mdata = this.state.opsdata._meta

            return(
                <div className={classes.top} >
                {Boolean(this.state.apiongoing) ? <LinearProgress /> : null}
                <Button color="default" className={classes.datebutton} onClick={this.fabClicked}>
                        Date range is {this.state.sdate} to {this.state.edate} 
                </Button>    
                <DateRangePicker open={this.state.drawopen} startdate={this.state.sdate} 
                        enddate={this.state.edate} callback={this.datepickerCallback} />
                
                {Object.keys(odata).map((key, i) => (
                       Boolean(mdata[key]) && Boolean(mdata[key].category === 'Live')) ?
                        <Paper className={classes.pdata} key={i}>
                            <span className={classes.tbltitle}> {mdata[key].title} </span>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        {this.tableRowsHeader(odata[key]).map((d, m) => {
                                            return(
                                            <TableCell key={m}> {d} </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                 {odata[key].map((d, k) =>  {
                                     return(
                                     <TableRow key={k}>
                                    {this.tableRowdata(d).map((e, j) => {
                                        return(
                                            <TableCell key={j}> {e} </TableCell>
                                        )
                                    })}
                                    </TableRow>
                                     )
                                 })}
                                </TableBody>
                            </Table>
                        </Paper>
                        : null
                    
                )
                }
                </div>
            )
        } else {
            return(
                <div className={classes.skeleton} >
                    <Skeleton variant="text"  height={20}/>
                    <Skeleton variant="rect"  height={118}/>
                    <Skeleton variant="text"  height={20} />
                    <Skeleton variant="rect"  height={118} />
                    <Skeleton animation="wave" />
                </div>
            )
        }
    }

}

Admin.contextType = UserContext; 


const astyle = {
    top: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //alignItems: 'center',
        marginBottom: 25,
        width: '80%'
        
    },
    skeleton: {
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        // marginBottom: 25,
        // width: '75%'
    },
    pdata: {
        padding: 10,
        margin: 10,
        //backgroundColor: colors.primarydark,
    },
    sdraw: {
        marginTop: 20
    },
    tbltitle: {
        fontSize: 'large'
    },
    rpaper: {
        //margin: 20,
        paddingTop: 10,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.bluegrey700,
        alignSelf: 'center',
        height: 200
        //flex: 1,
        // flexDirection: 'column',
        // alignItems: 'flex-start'
    },
    datebutton: {
        alignSelf: 'flex-start'
    }
    
}

export default withStyles(astyle)(Admin)