import React from 'react';
// import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import firebase from 'firebase';
import { BrowserRouter } from 'react-router-dom'

import LoginPage from './components/Loginpage'
import { CssBaseline } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  }
})



class App extends React.Component {

  componentDidMount () {
    const script = document.createElement("script");
    //script.src = "https://www.gstatic.com/firebasejs/7.15.5/firebase-app.js"
    script.async = true;
    //document.body.appendChild(script);
    script.src="https://www.gstatic.com/firebasejs/7.15.5/firebase-analytics.js"
    document.body.appendChild(script);

}


  render() {
    const {classes} = this.props
    //console.log( "Height: ",window.outerHeight, "Width: ", window.outerWidth)
    return (
      <ThemeProvider theme={theme} >
        <CssBaseline />
        <BrowserRouter>
        <div className={classes.appcontainer}>
          <LoginPage />
        </div>
        </BrowserRouter>
      </ThemeProvider>
    );

  }
  
}


const astyle = {
  appcontainer: {
    '@media(max-width: 1000px)': {
          // textAlign: 'center',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
    }
  }
}



export default withStyles(astyle)(App);
