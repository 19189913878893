// Searchri.js

import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';


import * as api from './Restapi'
import * as colors from './Colours'
import UserContext, { userDetail } from '../context/userContext'
import {SearchContext} from '../context/restContext'
import SearchResults from './Searchresult'


class SearchRI extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedcustomer: "",
            searchprovider: true,
            searchclaim: true,
            searchcustomer: true,
            searchdashboard: true, 
            searchicd: true,
            searchcpt: true,
            searchmessage: true,
            searchpayor: true,
            searchpayment: true,
            searchresponsedata: null,
            searchtext: "",
            customer: [ {name:"NTT", guid: 'b1e0f3ac-733b-4f8f-b792-7407add203dc'},
                        {name:"Lab2", guid: 'b1e0f3ac-733b-4f8f-b792-7407add0000'}
                        ],
            searchongoing: false
          };
    }


    selectCustomer = (event) => {
        //console.log("== cust", event.target.value)
        this.setState({selectedcustomer: event.target.value})
    }

    selectCheckbox = (event) => {
        console.log( "--->", event.target.name, event.target.checked)
        this.setState({ [event.target.name] : event.target.checked });
    }

    selectedEntities = () => {
        let e = []
        if(this.state.searchclaim) e.push('claim')
        if(this.state.searchcpt) e.push('cpt')
        if(this.state.searchcustomer) e.push('customer')
        if(this.state.searchdashboard) e.push('dashboard')
        if(this.state.searchicd) e.push('icd')
        if(this.state.searchmessage) e.push('message')
        if(this.state.searchpayment) e.push('payment')
        if(this.state.searchpayor) e.push('payor')
        if(this.state.searchprovider) e.push('provider')

        if(e.length > 0)
            return e
        else {
            e.push('claim')
            return e
        } 
    }
    

    submitSearch = () => {
        console.log("search submit", this.state.searchtext)
        let sdata = {}
        //sdata['search_text'] = "NE0G008417"   //""NE7G009541"    "NE7G00257"
        sdata['search_text'] = this.state.searchtext
        //sdata["customer_guid"] = "b1e0f3ac-733b-4f8f-b792-7407add203dc"
        sdata["num_of_results"] = 10
        sdata["search_entities"] = this.selectedEntities()

        let user = this.context;
        console.log( "idtoken ->", user)

        api.postCall(sdata, user.idtoken, "searchtext", this.updateSearchResponse )
        this.setState({searchongoing: true})
    }

    searchtextinput = (e) => {
        console.log("search i/p", e.target.value)
        this.setState({searchtext: e.target.value})
    }

    updateSearchResponse = (rdata) => {
        console.log("updateSearchreponse called")
        this.setState({searchresponsedata: rdata, searchongoing: false})
    }

    render() {
        const {classes} = this.props
        console.log("selected cust", this.state.selectedcustomer)

        return(
            <div className={classes.all}>

                <Paper className={classes.searchpaper} elevation={2}>
                    <div>
                    {Boolean(this.state.searchongoing) ? <LinearProgress /> : null }
                    <TextField
                        id="text-to-search"
                        label="text to search"
                        defaultValue=""
                        helperText=""
                        onChange={(e) => this.searchtextinput(e) }
                        className={classes.searchtext}
                        size="medium"
                        variant="standard"
                        inputProps={{ 'aria-label': 'naked' }}
                        labelwidth={100}
                        //fullWidth={true}
                        />
                        <IconButton type="submit" className={classes.iconButton} 
                                        aria-label="search" onClick={this.submitSearch}>
                                    <SearchIcon fontSize='large' color='primary'/>
                        </IconButton>
                        
                    </div>

                    </Paper>
                
                    <Divider light variant='fullWidth' /> 
                    
                   
                        
                    <Accordion className={classes.accord} >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <span className={classes.atext}> search settings </span>
                        </AccordionSummary>
                        <AccordionDetails>

                        <FormGroup row className={classes.fields}  >
                            
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchclaim} onChange={this.selectCheckbox} name="searchclaim" />}
                                label="Claim"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchprovider} onChange={this.selectCheckbox} name="searchprovider" />}
                                label="Provider"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchcustomer} onChange={this.selectCheckbox} name="searchcustomer" />}
                                label="Customer"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchpayment} onChange={this.selectCheckbox} name="searchpayment" />}
                                label="Payment"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchcpt} onChange={this.selectCheckbox} name="searchcpt" />}
                                label="CPT"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchicd} onChange={this.selectCheckbox} name="searchicd" />}
                                label="ICD"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchdashboard} onChange={this.selectCheckbox} name="searchdashboard" />}
                                label="Dashboard"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchpayor} onChange={this.selectCheckbox} name="searchpayor" />}
                                label="Payor"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.searchmessage} onChange={this.selectCheckbox} name="searchmessage" />}
                                label="Message"
                            />

                        </FormGroup>

                        </AccordionDetails>
                    </Accordion>
                    

                <SearchContext.Provider value={{search_response: this.state.searchresponsedata }} >
                    <SearchResults />
                </SearchContext.Provider>
               
            </div>
        )
    }

}

SearchRI.contextType = UserContext; 


const astyle = {
    all: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        
    },
    searchpaper: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10
        //paddingLeft: 25

    },
    iconButton: {
        padding: 10,
      },
    searchtext: {
       //flex: 1,
       borderBottomColor: 'green'
    },
    fields: {
        marginTop: 20
    },
    accord: {
        width: '40%',
        marginTop: 5
    },
    atext: {
        fontSize: 'small',
        color: colors.grey400
    }
}

export default withStyles(astyle)(SearchRI);
