// Claimutils.js


import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TreeItem from '@material-ui/lab/TreeItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';


import * as colors from './Colours'




const ClaimHeader = (props) => {
    const classes = astyle();
    let header = props.header

    return(
        <div className={classes.header} >
        <Paper className={classes.paper} >
            <div className={classes.nameage} >
                <div className={classes.title}> Patient: {"  "}
                    <span className={classes.titledata} >
                                {header.patient_first_name} {header.patient_last_name} </span>
                </div>
                <span className={classes.subtitle}> {header.patient_gender} {" | " } {header.patient_age}  yrs</span>
            </div>
            <div className={classes.title}> Physican: {"  "}
                <span className={classes.titledata} >
                        {header.referring_provider} </span> 
            </div>
            <div className={classes.title}> Service: {"  "}
                <span className={classes.titledata} >
                        {header.service_date} </span> 
            </div>

        </Paper>
        <div className={classes.sectitle}>
            <div className={classes.stitle}> Charged($): {"  "}
                <span className={classes.shdata} >
                        {header.claimed} </span>
            </div>
            <div className={classes.stitle}>  Paid($): {"  "}
            <span className={classes.shdata} >
                        {header.paid} </span>
            </div>
            <div className={classes.stitle}>  Balance($): {"  "}
            <span className={classes.shdata} >
                        {header.balance} </span>
            </div>
            <div>
                <div className={classes.stitle}> Claim:
                <span className={classes.shdata} >
                        {header.claim_number} </span>
                        </div>
            </div>
        </div>
        </div>
    )

}


const Table2ColNoheader = (props) => {
    const classes = astyle();
    let tabledata = props.data

    return(
        <TableContainer component={Paper} className={classes.servicebox2}>
        <Table className={classes.table} size="small" padding='checkbox' aria-label="a dense table">
            <TableBody>
            {tabledata.map((d,i) => (
                <TableRow key={i}>
                <TableCell component="th" scope="row" size='small'>
                    <span className={classes.tbltext1}> {d[0]} </span>
                </TableCell>
                <TableCell align="right"><span className={classes.tbltext2}> {d[1]}</span></TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )

}

const Table3ColNoheader = (props) => {
    const classes = astyle();
    let tabledata = props.data

    return(
        <TableContainer component={Paper} className={classes.servicebox2}>
        <Table className={classes.table} size="small" padding='checkbox' aria-label="a dense table">
            <TableBody>
            {tabledata.map((d,i) => (
                <TableRow key={i}>
                <TableCell component="th" scope="row" size='small' >
                    <span className={classes.tbltext1}> {d[0]} </span>
                </TableCell>
                <TableCell align="right"><span className={classes.tbltext1}> {d[1]}</span></TableCell>
                <TableCell align="right"><span className={classes.tbltext1}> {d[2]}</span></TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )

}

const Table4ColNoheader = (props) => {
    const classes = astyle();
    let tabledata = props.data

    return(
        <TableContainer component={Paper} className={classes.servicebox2}>
        <Table className={classes.table} size="small" aria-label="a dense table">
            <TableBody>
            {tabledata.map((d,i) => (
                <TableRow key={i}>
                <TableCell component="th" scope="row" size='small'>
                    <span className={classes.tbltext1}> {d[0]} </span>
                </TableCell>
                <TableCell align="right"><span className={classes.tbltext1}> {d[1]}</span></TableCell>
                <TableCell align="right"><span className={classes.tbltext1}> {d[2]}</span></TableCell>
                <TableCell align="right"><span className={classes.tbltext1}> {d[3]}</span></TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )

}

const CDate = (props) => {
    const classes = astyle();
    let dt = props.date.split('-')
    let year = dt[0]; let month = dt[1]; let day = dt[2]
    return(
        <div className={classes.datecircle}>
                <div className={classes.mday}> {month} {" "} {day}</div>
                <div>{year}</div>
        </div>
    )
}

const Line = (props) => {
    let toptext = ""; let bottomtext = ''
    let margintop = 20
    if(Boolean(props.toptext)) {
        toptext = props.toptext
        margintop = 3
    }
    if(Boolean(props.bottomtext))
        bottomtext = props.bottomtext


    return (
        <div>
            <span style={{marginTop: 20,padding:0, color:'white'}}> {toptext} </span>
            <div style={{
                height: 2,
                marginTop: margintop,
                //flex: 3,
                //width: 40,
                backgroundColor: colors.bluegrey300,
                //alignSelf: 'center',
                //borderBottomColor: 'white'
                // flex: 1
                width: 2,
                flexGrow: 1,
                maxWidth: 60,
                minWidth: 60
            }}
            
            />
            <span>{bottomtext} </span>
            </div>
    )
}


const obsIcon = (k) => {
    if(k === 'Anamoly')  {
        return <WarningRoundedIcon />
    } else if(k === 'Threshold') {
        return <PublishRoundedIcon />
    } else {
        return <ErrorRoundedIcon />
    }
}

const ObsDetails = (props) => {
    const classes = astyle();
    let msg = props.msg
    return(
        <List dense={true} className={classes.obsbox} >
            {Object.keys(msg).map((key, i) => (
                <ListItem key={i}>
                    <ListItemAvatar>
                        <Avatar size="small" >
                        {obsIcon(key)}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={key} 
                        secondary={msg[key].map((d,i) =>  
                            {return(<span key={i} className={classes.obsec}> {d} </span>)} )} 
                            />
                </ListItem>
            ))
            }
        </List>
    )
}

const dict2Str = (d) => {
    console.log('===>', d)
    if(Boolean(d)) {
        for(const [key, value] of Object.entries(d)) {
            return key + ' ' + value
        }
    }
    return null
}

const DiffDetails = (props) => {
    const classes = astyle();
    let msg = props.msg
    
    return(
        <List dense={true} className={classes.obsbox} >
                <ListItemText primary='Difference between this and previous entry' />
                {Object.keys(msg).map((key, i) => (
                <ListItem key={i}>
                    <ListItemText primary={key} 
                        secondary={dict2Str(msg[key])}
                            />
                </ListItem>
            ))
            }
        </List>
    )
}



const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    
  }));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
  
    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    );
  }
  




const astyle = makeStyles((theme) => ({
    header: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    paper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%',
        //margin: 20,
        backgroundColor: colors.bluegrey700
    },
    title: {
        fontSize: 'small',
        color: colors.grey400,
        flexDirection: 'row'
    },
    titledata: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: 'white'
    },
    nameage: {
        flexDirection: 'row'
    },
    subtitle: {
        fontSize: 'small',
        color: 'white',
        marginLeft: 50
    },
    sectitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 10,
        width: '80%'
    },
    stitle: {
        fontSize: 'small',
        color: 'grey',
    },
    stdata: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: 'white'
    },
    shdata: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: colors.bluegrey800
    },
    papertl: {
        backgroundColor: colors.bluegrey200,
        width: '70%'
    },
    datecircle: {
        width: 60,
        height: 60,
        borderRadius: 60/2,
        backgroundColor: colors.bluegrey400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'

    },
    stl: {
        marginTop: 10,
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        //justifyContent: 'space-evenly',
        position: 'relative'
    },
    mday: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: 'white'
    },
    servicebox: {
        backgroundColor: colors.bluegrey300,
        //flex: 1,
        padding: 10,
        marginLeft: 20,
        // position: 'absolute',
        // right: '10%',
        //alignSelf: 'center',
    },
    servicebox2: {
        backgroundColor: colors.primarydark,
        //flex: 2,
        padding: 5,
        marginLeft: 20,
        marginBottom: 4,
        //marginRight: 4,
        width: '95%'
        //height: '50%'
    },
    oppcontent: {
        padding: 0,
        margin: 10,
        textAlign: 'left',
        alignSelf: 'center',
        flex: 0
    },
    tlc1: {
        display:'flex',
        width: '100%',
        flexDirection: 'row'
        //right: '10%'
    },
    rowbox: {
        display: 'flex',
        flexDirection: 'column'
    },
    hline: {
        width: 100,
        height: 4,
        backgroundColor: colors.bluegrey100
    },
    tbltext1: {
        fontSize: 'small',
        color: 'white'
    },
    tbltext2: {
        fontSize: 'large',
        color: 'white'
    },
    obstext: {
        fontSize: 'small',
        fontWeight: 'Semi-bold',
        color: 'white',
        margin: 2
    },
    obsbox: {
        backgroundColor: colors.bluegrey300,
        opacity: 1
    },
    table: {
        margin: 3
    },
    obsec: {
        whiteSpace: 'pre'
    }
    
}));

export {ClaimHeader, Table2ColNoheader, Table4ColNoheader, Table3ColNoheader,
                Line, StyledTreeItem, ObsDetails, DiffDetails }