// SearchResult.js

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress'

import {
    BrowserRouter,
    Switch,
    Route, Redirect
  } from "react-router-dom";

import * as api from './Restapi'
import ClaimDetails from './Claimdetails'
import {SearchContext, ClaimDetailContext} from '../context/restContext'
import UserContext from '../context/userContext';

const SearchResults = (props) => {
    const classes = astyle();
    const [ sitem, setItemdetails ] = useState({data: null, searchongoing: false})
    console.log("in SearchResults")
    const rd = useContext(SearchContext)
    const user = useContext(UserContext)

    console.log(rd)
    let resp = rd.search_response

    const searchitemClicked = (item) => {
        console.log("seach item clicked", item)
        if(item.entity === 'claim') {
            let sdata = {}
            sdata['customer_guid'] = "b1e0f3ac-733b-4f8f-b792-7407add203dc"
            sdata['object_guid'] = item.guid
            api.postCall(sdata, user.idtoken, "claimdetail", updateClaimDetailsResponse )
            //setItemdetails({itemtype: 'claim', itemids: item})
            setItemdetails({...sitem, searchongoing: true})
        }
    }

    const updateClaimDetailsResponse = (data) => {
        console.log("updateClaimDetail reponse called")
        setItemdetails({data: data, searchongoing: false})
    }

    // if(Boolean(sitem.itemtype) && sitem.itemtype === 'claim') {
    if(Boolean(sitem.data) && sitem.data.data.entity.type === 'claim') {
        console.log("state item type set to claim", sitem.data.data.entity.type)
        return(       
            <div>
                <Redirect to={{pathname: '/claimdetails', state: {data: sitem.data}}} />
            </div>
        ) 
    } else if(Boolean(resp) && resp.status === 'success') {
        var res = resp.data.results
        var nosresult = resp.data.num_of_results
        if(nosresult === 0) {
            return(
                <Paper className={classes.paper} >
                    <div className={classes.noresult}> your search did not match any documents </div>
                </Paper>
            )
        }
        else {
            return(
            <div className={classes.resdiv} >
            {Boolean(sitem.searchongoing) ?  <LinearProgress /> : null }
            <Paper className={classes.paper} >
                {res.map((r,i) => {
                    let tt = r.teaser; let teasetext = ""
                    if(r.entity ===  'claim') {
                        teasetext = tt.first_name  + ' , ' + tt.last_name + ' | policy: ' + tt.policy_number + ' | service: ' + tt.service_date
                    }
                    return(
                    <div key={i} className={classes.searchres}>
                        <span className={classes.entitytext}> {r.entity} , rank: {r.rank} </span>
                            <Button className={classes.sbut} color={"primary"} onClick={() => searchitemClicked(r)}>
                                            {r.title} </Button>
                        <span className={classes.entitytext}> {teasetext} </span>
                        <Divider className={classes.divider} light variant='middle' />
                    </div>
                    )}
                )}              
            </Paper>   
            </div>            
            )
        }

    } else {
        return null
    }

}


const astyle = makeStyles((theme) => ({
    detail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        marginTop: 25,
        marginBottom: 30,
        backgroundColor: '#b0bec5',
        width: '60%'
    },
    resdiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    entitytext: {
        fontSize: 'small',
        fontWeight: 'Book'
    },
    titletext: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: 'blue'
    },
    usertext: {
        fontSize: 'small'
    },
    searchres: {
        marginTop: 15,
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    divider: {
        marginTop: 3
    },
    sbut: {
        margin: 0,
        padding: 0
    },
    noresult: {
        margin: 20,
        fontSize: 'large'
    }

}))

export default SearchResults