// Alloptions-sdraw.js

import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import SearchIcon from '@material-ui/icons/Search'
import DashboardIcon from '@material-ui/icons/Dashboard'
import WorkIcon from '@material-ui/icons/Work'
import HomeIcon from '@material-ui/icons/Home'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SliderSwitch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import { Route, MemoryRouter } from 'react-router';
import { BrowserRouter, Route, Switch, MemoryRouter, Link } from 'react-router-dom';


import { ArtissTextLogo } from './Artisslogo'
import LoginDetails from './Logindetails'
import RIRoutes from './RIRoutes'
import SearchRI from './Searchri'
import ClaimDetails from './Claimdetails'
import Admin from './Admin'

import * as colors from './Colours'
import { isConditionalExpression } from '@babel/types';
import { FormControl } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logindetail: {
    position: 'absolute',
    right: '1%'
    },
content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  maincontent: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 100
  },
  ab: {
    backgroundColor: colors.artissdark
  },
  appBar: {
    zIndex: 1400         // so that is show on top of the drawer
  },
}));

export default function AllOptionsSwipeableDrawer(props) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    left: false,
    //open: false
  });
  const [darktheme, setDarkState] = React.useState(true)
  const themechoice = darktheme ? 'dark' : 'light'
  const theme = createMuiTheme({
    palette: {
      type: themechoice
    }
  })
  //const [open, setOpen] = React.useState(false);

  const handleThemeChange = () => {
    setDarkState(!darktheme)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  return (
    <ThemeProvider theme={theme} >
    <div >
        <CssBaseline />
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
            [classes.appBarShift]: state.left,
            },classes.ab)}
          
        >
            <Toolbar >
                <IconButton
                    color={'inherit'}
                    aria-label="open drawer"
                    onClick={toggleDrawer('left', true)}
                    edge="start"
                    className={clsx(classes.menuButton, {
                    [classes.hide]: state.left,
                    })}
                >
                    <MenuIcon />
                </IconButton>
                <ArtissTextLogo base='white'/>
                <FormControlLabel value='top' 
                control={<SliderSwitch size="small" checked={darktheme} onChange={handleThemeChange} />}
                label='theme'
                labelPlacement='top'
                />

                <div className={classes.logindetail}> <LoginDetails /> </div>
            </Toolbar>
        </AppBar>

        <SwipeableDrawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
        >
            <div
              onClick={toggleDrawer('left', false)}
              onKeyDown={toggleDrawer('left', false)} >
            <List>
                <Link to="/" color='textprimary' >
                    <ListItem button key={'Home'}>
                        <ListItemIcon> <HomeIcon />  </ListItemIcon>
                        <ListItemText primary={'Home'} />
                    </ListItem>
                </Link>
                <Link to="/searchri" >
                    <ListItem button key={'Search'}>
                        <ListItemIcon> <SearchIcon />  </ListItemIcon>
                        <ListItemText primary={'Search'} />
                    </ListItem>
                </Link>
                <Divider />
                <Link to="/admin" >
                    <ListItem button key={'Ops Status'}>
                        <ListItemIcon> <SettingsApplicationsIcon />  </ListItemIcon>
                        <ListItemText primary={'Ops Status'} />
                    </ListItem>
                </Link>
                
        
            </List>
            </div>
           
        </SwipeableDrawer>
        <div className={classes.maincontent}>
          <Switch>
              <Route exact path="/" component={SearchRI} />
              <Route exact path="/searchri" >
                  <SearchRI />
              </Route>
              <Route exact path="/admin" component={Admin} /> 
              <Route exact path="/claimdetails" component={ClaimDetails} />
          
          </Switch>
        </div>
    
    </div>
    </ThemeProvider>
  );
}
