// Logindetails.js

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import UserContext from '../context/userContext'

import * as colours from './Colours'


const LoginDetails = (props) => {
    const classes = astyle();

    return(<UserContext.Consumer>
            {user => (
                <div className={classes.detail}>
                    <AccountCircleIcon />
                    <span className={classes.usertext}> {user.name} </span>
                    <span className={classes.usertext}> {user.email} </span> 
                </div>
                )}
        </UserContext.Consumer>

        
    )

}


const astyle = makeStyles((theme) => ({
    detail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    usertext: {
        fontSize: 'small'
    }
    

}))

export default LoginDetails 
