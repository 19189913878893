// Restapi.js
// copyrights artiss.co

//import React from 'react';


//const APIBaseUrl = "https://us-east1-instant-bonbon-259705.cloudfunctions.net/data"
const APIBaseUrl = "https://api.artiss.co/data"



function postCall(sendata, authtoken, callname, callback) {
    console.log( "REST postSearch", sendata)

    let url = ""
    if(callname === 'searchtext') {
         url = APIBaseUrl + '/web_search_text'
    } else if(callname === "claimdetail") {
         url = APIBaseUrl + '/web_get_claim'
    } else if(callname === "submissiondetail") {
        url = APIBaseUrl + '/web_get_claim_submission'
    } else if(callname === "responsedetail") {
        url = APIBaseUrl + '/web_get_claim_response'
    } else if(callname === "claimobservation") {
        url = APIBaseUrl + "/web_get_observations"
    }else if(callname === 'adminOpsStatus') {
        url = APIBaseUrl + "/web_query_table"
    }
    

    let autheader = 'Bearer ' + authtoken
    console.log( "restapi got Bearear token:", autheader)
    fetch(url, {
        method: 'post',
        //credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': autheader
        },
        body: JSON.stringify(sendata)
    })
    .then(res => {
        if(!res.ok) {
            console.log("postcall return error", res )
        } else {
            return res.json()
        }
    }).then((data) => {
        console.log("postcall got data", data)
        callback(data)
    })
    .catch((e) => {
            console.log("postcall error",e)
    })
}

function postSearchget(authtoken) {

    let url = "https://us-central1-instant-bonbon-259705.cloudfunctions.net/web_echo"

    let autheader = 'Bearer ' + authtoken
    fetch(url, {
        method: 'get',
        //credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': {autheader}
        }
    })
    .then(res => {
        if(!res.ok) {
            console.log("return error" )
            
        } else {
            console.log("getagentdetails ", res)  
            return res.json()    
        }
    }).then((data) => {
        console.log( "getAgentDetails data", data)
        
    })
    .catch((e) => {
            console.log("getagentdetails errror",e)
    })

} 



export { postCall, postSearchget }