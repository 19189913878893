// Daterangepicker.js

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import * as colors from './Colours'


class DateRangePicker extends React.Component {

    constructor(props) {
        super(props)
        let sdate = null; let edate = null
        if(Boolean(this.props.startdate) || Boolean(this.props.enddate)) {
            sdate = this.props.startdate
            edate = this.props.enddate
        } else {
            let td = new Date()
            edate = new Date().toLocaleDateString('en-ZA')    // today
            let yes = td.setDate(td.getDate() -1)
            sdate = new Date(yes).toLocaleDateString('en-ZA')  // yesterday
        }
        this.state = { 
            startdate: sdate, enddate: edate
            }
    }

    cancelClick = () =>{
        this.props.callback(false, null, null)
    }

    applyClick = () => {
        console.log( "dates", this.state.startdate, this.state.enddate)
        this.props.callback(false, this.state.startdate, this.state.enddate)

    }

    toggleDrawer = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
          this.setState({drawopen: !this.state.drawopen})
    }
    startDateChange = (date) => {
        let sd = new Date(date).toLocaleDateString('en-ZA') 
        //console.log( 'start', sd)
        //console.log("start", typeof(date), date)
        this.setState({startdate: sd});
      }
    endDateChange = (date) => { 
        let ed = new Date(date).toLocaleDateString('en-ZA') 
        //onsole.log("end",  date)
        this.setState({enddate: ed});
      };

    

    render() {
        const {classes} = this.props
        let drawopen = this.props.open
        console.log( "datepicker open", drawopen)
        return(
            
            <SwipeableDrawer 
                anchor={'top'}
                open={drawopen}
                onClose={this.toggleDrawer}
                onOpen={this.toggleDrawer}
                >            
                        <div className={classes.h} /> 
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-evenly">
                            <KeyboardDatePicker key={'start'}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="start-date-picker-inline"
                            label="Start date"
                            value={this.state.startdate}
                            onChange={this.startDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                            <KeyboardDatePicker key={'end'}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="end-date-picker-inline"
                            label="End date"
                            value={this.state.enddate}
                            onChange={this.endDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                            
                            
                        </Grid>
                        </MuiPickersUtilsProvider>
                        
                        <div>
                        <Button size={'small'} onClick={this.cancelClick}> cancel </Button> 
                        <Button color="primary"  size={'large'} className={classes.applybut} 
                                onClick={this.applyClick}> Apply </Button> 
                        </div>
                        
                    
                   
                
            </SwipeableDrawer>
            
        )
    }


}

const astyle = {
    top: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //alignItems: 'center',
        marginBottom: 25,
        width: '80%'
        
    },
    sdraw: {
        display: "flex",

        width: '100%',
        marginTop: 10,
        //height: 100
    },
    h: {
        height: 0,
        margin: 40
    },
    applybut: {
        marginLeft: 40
    }
    
    
}

export default withStyles(astyle)(DateRangePicker)
