import React from 'react';
import {withStyles} from '@material-ui/core/styles'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import AllOptions from './Alloptions'
import AllOptionsSwipeableDrawer from './Alloptions-sdraw'
import SearchRI from './Searchri'
import ClaimDetails from './Claimdetails'
import * as colours from './Colours'

import UserContext from '../context/userContext'

var firebase = require('firebase/app');
var firebaseui = require('firebaseui');

var firebaseConfig = {
    apiKey: "AIzaSyCyBIjs_exvUaBst_usqhFpQSmusMD3toU",
    //apiKey: "AIzaSyANTlc3LDvD8L0j8QI3vyClfnOe-mWbzH8",   - not used..
    authDomain: "instant-bonbon-259705.firebaseapp.com",
    databaseURL: "https://instant-bonbon-259705.firebaseio.com",
    projectId: "instant-bonbon-259705",
    storageBucket: "instant-bonbon-259705.appspot.com",
    messagingSenderId: "1035367451441",
    appId: "1:1035367451441:web:acb73e7d947727c0b1fe10",
    measurementId: "G-DS86ESEV85"
    };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
//const ui = new firebaseui.auth.AuthUI(firebase.auth());
const uiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
  }
//ui.start('#firebaseui-auth-container', uiConfig);


class LoginPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            firebaseuser: null,
            isSignedIn: false, // Local signed-in state.
            name: null,
            email: null,
            photo: null,
            idtoken: null
          };
        
    }

    componentDidMount() {
        
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => { 
                console.log("user Auth state changed")
                // TODO  catch condition when user logs out
                user.getIdToken(true)
                .then((idToken) => {
                    console.log("got idToken", idToken)
                    this.setState({firebaseuser: user, isSignedIn: !!user, name: user.displayName, 
                        email: user.email, photo: user.photoURL,
                        idtoken: idToken })
                })
                .catch((err) => {
                    console.log("get token error", err)
                })
            })
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        const {classes} = this.props

        if (!this.state.isSignedIn) {
            return(
                <div className={classes.top} >
                    <div className={classes.artisslogo}> ARTISS </div>
                    <div className={classes.welcome}>Revenue Insight admin console, tread lightly </div>

                    {/* <div className={classes.fauth} id="firebaseui-auth-container" /> */}
                    <StyledFirebaseAuth className={classes.fauth} uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>

                </div>
            )
        
        }

        console.log("before return", this.state.idtoken)
        return (
            
                <UserContext.Provider value={{name: this.state.name, 
                                                email: this.state.email,
                                                isSignedIn: this.state.isSignedIn,
                                                idtoken: this.state.idtoken}}>
                    {/* <AllOptions  /> */}
                        <AllOptionsSwipeableDrawer />
                         
                </UserContext.Provider>
                
          );
        
    }

}

const astyle = {
    top: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2%',
        //width: 800,
        //backgroundColor: colours.bluegrey100,
        borderRadius: 5
    },
    artisslogo: {
        fontSize: 'xx-large',
        fontWeight: 'bold',
        letterSpacing: 3,
        color: colours.purple600,
        justifyContent: 'flex-start'
    },
    welcome: {
        marginTop: 5,
        fontSize: 'medium',
        letterSpacing: 3,
        color: 'grey'
    },
    fauth: {
        marginTop: 50

    }
  }

export default withStyles(astyle)(LoginPage);
